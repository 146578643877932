import { onMounted, Ref, ref, useStore, watch } from '@nuxtjs/composition-api'
import { getAddressComponent } from '~/lib/addresses-helpers'
import { ICoordinates, IZoomInfo } from '~/types/app/Location'
import { Geocoder } from '~/hooks/google/useGoogleMapsGeocoder'
import IRootState from '~/types/app/State'
import { ZOOM_LEVEL_DETAIL, ZOOM_LEVEL_OVERVIEW } from '~/lib/constants'
import { GoogleMapsApi } from '~/hooks/google/useGoogleMapsApi'
import { throttle } from 'lodash-es'

interface IInput {
  googleMapsApi: Ref<GoogleMapsApi | undefined>
  mapRef: Ref<HTMLDivElement | null>
  initialAddress: string
  locationCoordinates: ICoordinates
  geocoder: Ref<Geocoder>
  zoomInfo: IZoomInfo | undefined
  hasInteracted: Ref<boolean> | undefined
  onDragEnd?: () => void
}

interface IResult {
  map: Ref<google.maps.Map | undefined>
  pinDistanceMeters: Ref<number>
}

const DRAG_THROTTLE_TIME = 300

export default function useGoogleMapsMap({
  googleMapsApi,
  mapRef,
  initialAddress,
  locationCoordinates,
  geocoder,
  zoomInfo,
  hasInteracted,
  onDragEnd,
}: IInput): IResult {
  const store = useStore<IRootState>()

  const map = ref<google.maps.Map | undefined>()
  const pinDistanceMeters = ref(0)
  const address = ref(initialAddress)
  const initialAddressCoordinates = ref<google.maps.LatLng>()
  const postalCode = ref('')

  onMounted((): void => {
    handleInit()
  })
  watch(
    [googleMapsApi, () => initialAddress, () => locationCoordinates],
    handleInit
  )

  async function handleInit(): Promise<void> {
    if (!googleMapsApi.value) return

    await geocodeAddress()

    if (!initialAddressCoordinates.value) {
      return
    }

    const initialCoordinates = locationCoordinates
      ? new googleMapsApi.value.LatLng({
          lat: locationCoordinates.latitude,
          lng: locationCoordinates.longitude,
        })
      : initialAddressCoordinates.value
    initMap(initialCoordinates)
  }

  function initMap(locationCoordinates: google.maps.LatLng): void {
    if (!googleMapsApi.value) {
      return
    }

    const mapElement = mapRef.value as HTMLElement

    const initialZoom = initialAddress ? ZOOM_LEVEL_DETAIL : ZOOM_LEVEL_OVERVIEW

    if (zoomInfo && !zoomInfo.isSetByUser) {
      zoomInfo.currentZoom.value = initialZoom
    }

    const createdMap = new googleMapsApi.value.Map(mapElement, {
      zoom: zoomInfo?.currentZoom.value ?? initialZoom,
      center: locationCoordinates,
      disableDefaultUI: true,
    })

    createdMap.addListener(
      'center_changed',
      throttle(
        () => {
          if (hasInteracted) {
            hasInteracted.value = true
          }
          updatePinDistance()
        },
        DRAG_THROTTLE_TIME,
        { leading: true }
      )
    )

    if (onDragEnd) {
      createdMap.addListener('dragend', () => {
        updatePinDistance()
        onDragEnd()
      })
    }

    createdMap.addListener('zoom_changed', () => {
      const measuredZoom = createdMap.getZoom()
      if (zoomInfo && measuredZoom) {
        zoomInfo.currentZoom.value = measuredZoom
        zoomInfo.isSetByUser.value = true
      }
    })

    map.value = createdMap
  }

  function updatePinDistance(): void {
    const center = map.value?.getCenter()

    if (!center || !initialAddressCoordinates.value) {
      pinDistanceMeters.value = 0
      return
    }

    pinDistanceMeters.value =
      google.maps.geometry.spherical.computeDistanceBetween(
        center,
        initialAddressCoordinates.value
      )
  }

  async function geocodeAddress(): Promise<void> {
    if (!geocoder.value) {
      return
    }

    if (initialAddress) {
      try {
        const geocoded = await geocoder.value.geocode({
          address: initialAddress,
        })
        postalCode.value = getAddressComponent(
          'postal_code',
          geocoded.results[0]
        )
        address.value = geocoded.results[0].formatted_address
        initialAddressCoordinates.value = geocoded.results[0].geometry.location
      } catch (e) {
        initFallbackLocation()
      }
    } else {
      initFallbackLocation()
    }
  }

  function initFallbackLocation(): void {
    if (!googleMapsApi.value) {
      return
    }

    initialAddressCoordinates.value = new googleMapsApi.value.LatLng({
      lat: store.state.geocodeFallbackCoordinates.latitude,
      lng: store.state.geocodeFallbackCoordinates.longitude,
    })
  }

  return {
    map,
    pinDistanceMeters,
  }
}
