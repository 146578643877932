




import { defineComponent, ref } from '@nuxtjs/composition-api'
import { ICoordinates } from '~/types/app/Location'
import useGoogleMapsPrintMarkers from '~/hooks/order-status/useGoogleMapsPrintMarkers'
import { ZOOM_LEVEL_DETAIL } from '~/lib/constants'

export default defineComponent({
  props: {
    coordinates: {
      type: Object as () => ICoordinates,
      required: true,
    },
    options: {
      type: Object as () => google.maps.MapOptions,
      default: () => ({}),
    },
    hideCenterIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const map = ref<HTMLDivElement | null>(null)

    const icon = props.hideCenterIcon
      ? {
          path: '',
        }
      : require('~/assets/icons/user-pin.svg')

    useGoogleMapsPrintMarkers(
      map,
      [
        {
          location: props.coordinates,
          icon,
          isVisible: true,
        },
      ],
      {
        zoom: props.options.zoom ?? ZOOM_LEVEL_DETAIL,
        ...props.options,
      }
    )

    return { map }
  },
})
