



















import { ICoordinates } from '~/types/app/Location';
import { onMounted, useContext, useRoute, ref } from '@nuxtjs/composition-api';
import { ETrackingType, EScreenName, getScreenName, EComponentName } from '~/lib/segment';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  streetAddress: {
    type: String,
    required: true
  },
  initialAddress: {
    type: String,
    default: ''
  },
  locationCoordinates: {
    type: (Object as () => ICoordinates),
    default: null
  },
  needsUserInteraction: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const {
    $segmentEvent
  } = useContext();
  const emit = __ctx.emit;
  const editDistance = ref<number>(0);
  onMounted(() => {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.screenViewed,
      screenName: EScreenName.addressEditEntrance,
      eventOrigin: getScreenName(route.value)
    });
  });

  function onMapDragend(distanceMeters: number) {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressEditEntrance,
      componentName: EComponentName.locationPin,
      componentValue: distanceMeters,
      eventOrigin: getScreenName(route.value)
    });
    editDistance.value = distanceMeters;
  }

  function trackPinMovedTooFar(): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.sectionViewed,
      screenName: EScreenName.addressEditEntrance,
      componentName: EComponentName.locationPinExceededDistanceText,
      eventOrigin: getScreenName(route.value)
    });
  }

  function onCoordinatesUpdated(newCoordinates: ICoordinates): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressEditEntrance,
      componentName: EComponentName.entranceConfirmButton,
      componentValue: editDistance.value,
      eventOrigin: getScreenName(route.value)
    });
    emit('success', newCoordinates);
  }

  return {
    onMapDragend,
    trackPinMovedTooFar,
    onCoordinatesUpdated
  };
};

export default __sfc_main;
