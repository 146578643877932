














































































import { computed, onMounted, provide, Ref, ref, useContext, useRoute } from '@nuxtjs/composition-api';
import { IAddress, IAddressBookAddress, ICoordinates, IZoomInfo } from '~/types/app/Location';
import { cloneDeep } from 'lodash-es';
import { ZOOM_LEVEL_DETAIL } from '~/lib/constants';
import VueI18n from 'vue-i18n';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { listAddressToShippingData } from '~/lib/addresses-helpers';
import useFullAddressString from '~/hooks/onboarding/useFullAddressString';
import { EComponentName, EScreenName, ETrackingType, getScreenName } from '~/lib/segment';
import { areCoordinatesTheSame } from '~/lib/helpers';
enum EditState {
  Main = 'Main',
  Entrance = 'Entrance',
}
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  hasPreviousState: {
    type: Boolean,
    default: false
  },
  address: {
    type: (Object as () => IAddressBookAddress),
    required: true
  },
  canDeleteAddress: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    i18n,
    $segmentEvent
  } = useContext();
  const route = useRoute();
  const props = __props;
  const emit = __ctx.emit;
  const state = ref<EditState>(EditState.Main);
  const editedAddress = ref(cloneDeep(props.address));
  const currentZoomPickerMap = ref<number>(ZOOM_LEVEL_DETAIL);
  const isZoomSetByUser = ref<boolean>(false);
  provide<IZoomInfo>('zoomInfo', {
    currentZoom: currentZoomPickerMap,
    isSetByUser: isZoomSetByUser
  });
  const currentZoomStaticMap = ref<number>(currentZoomPickerMap.value);
  const hasUserSpecifiedEntrance = ref<boolean>(false);
  provide<Ref<boolean>>('hasUserSpecifiedEntrance', hasUserSpecifiedEntrance);
  onMounted(() => {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.screenViewed,
      screenName: EScreenName.addressEdit,
      componentName: props.canDeleteAddress ? EComponentName.deleteAddressEnabled : EComponentName.deleteAddressDisabled,
      eventOrigin: getScreenName(route.value)
    });
  });
  const isInInitialState = computed<boolean>(() => state.value === EditState.Main);
  const changedValues = computed<{
    entrance: boolean;
    note: boolean;
  }>(() => {
    const originalAddress = props.address;
    const changedAddress = editedAddress.value;
    return {
      entrance: !areCoordinatesTheSame(originalAddress, changedAddress),
      note: originalAddress.comment !== changedAddress.comment
    };
  });
  const canSave = computed<boolean>(() => {
    return Object.values(changedValues.value).some(val => val);
  });
  const heading = computed<VueI18n.TranslateResult>(() => {
    switch (state.value) {
      case EditState.Main:
        return i18n.t('edit_address_header');

      case EditState.Entrance:
        return i18n.t('edit_address_map_header');
    }
  });
  const shippingAddress = computed<IAddress>(() => {
    return listAddressToShippingData(props.address).address;
  });
  const fullAddressString = useFullAddressString({
    address: shippingAddress
  });
  const coordinates = computed<ICoordinates | undefined>(() => {
    return {
      latitude: editedAddress.value.latitude,
      longitude: editedAddress.value.longitude
    };
  });

  function onBack(): void {
    switch (state.value) {
      case EditState.Entrance:
        currentZoomPickerMap.value = currentZoomStaticMap.value;
        state.value = EditState.Main;
        break;

      case EditState.Main:
        emit('go-back');
        break;
    }
  }

  function onEditEntrance() {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressEdit,
      componentName: EComponentName.markEntranceButton,
      eventOrigin: getScreenName(route.value)
    });
    state.value = EditState.Entrance;
  }

  function onCoordinatesUpdated(newCoordinates: ICoordinates): void {
    editedAddress.value = { ...editedAddress.value,
      latitude: newCoordinates.latitude,
      longitude: newCoordinates.longitude
    };
    currentZoomStaticMap.value = currentZoomPickerMap.value;
    state.value = EditState.Main;
  }

  function onDeleteAddress() {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressEdit,
      componentName: EComponentName.deleteButton,
      eventOrigin: getScreenName(route.value)
    });
    emit('delete-address');
  }

  function onSaveChanges() {
    const {
      entrance: isEntranceChanged,
      note: isNoteChanged
    } = changedValues.value;
    const {
      latitude: changedLatitude,
      longitude: changedLongitude
    } = editedAddress.value;
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: EScreenName.addressEdit,
      componentName: EComponentName.saveButton,
      componentContent: `change${isEntranceChanged ? '_entrance' : ''}${isNoteChanged ? '_note' : ''}`,
      componentValue: `${changedLatitude}|${changedLongitude}`,
      eventOrigin: getScreenName(route.value)
    });
    emit('save-changes', editedAddress.value);
  }

  return {
    EditState,
    emit,
    state,
    editedAddress,
    currentZoomStaticMap,
    isInInitialState,
    canSave,
    heading,
    fullAddressString,
    coordinates,
    onBack,
    onEditEntrance,
    onCoordinatesUpdated,
    onDeleteAddress,
    onSaveChanges
  };
};

__sfc_main.components = Object.assign({
  PulseLoader
}, __sfc_main.components);
export default __sfc_main;
