





















































import { computed, inject, ref, Ref, watch } from '@nuxtjs/composition-api';
import { ICoordinates, IZoomInfo } from '~/types/app/Location';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import useGoogleMapsGeocoder from '~/hooks/google/useGoogleMapsGeocoder';
import useGoogleMapsMap from '~/hooks/google/useGoogleMapsMap';
import useGoogleMapsApi from '~/hooks/google/useGoogleMapsApi';
import { IMapLoaded } from '~/types/app/Onboarding';
import { EBannerMessageVariant } from '~/types/app/BannerMessage';
import { MAX_ENTRANCE_DISTANCE_METERS, ZOOM_LEVEL_DETAIL } from '~/lib/constants';
// need this for default export to be detected
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  streetAddress: {
    type: String,
    required: true
  },
  initialAddress: {
    type: String,
    default: ''
  },
  locationCoordinates: {
    type: (Object as () => ICoordinates),
    default: null
  },
  needsUserInteraction: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const isLoading = ref<boolean>(false);
  const mapRef = ref<HTMLDivElement | null>(null);
  const zoomInfo = inject<IZoomInfo>('zoomInfo', {
    currentZoom: ref(ZOOM_LEVEL_DETAIL),
    isSetByUser: ref(false)
  });
  const hasUserSpecifiedEntrance = inject<Ref<boolean>>('hasUserSpecifiedEntrance', ref(false));
  const googleMapsApi = useGoogleMapsApi();
  const geocoder = useGoogleMapsGeocoder(googleMapsApi);
  const {
    map,
    pinDistanceMeters
  } = useGoogleMapsMap({
    googleMapsApi,
    mapRef,
    initialAddress: props.initialAddress,
    locationCoordinates: props.locationCoordinates,
    geocoder,
    zoomInfo,
    hasInteracted: hasUserSpecifiedEntrance,
    onDragEnd
  });
  watch(map, () => emit('map-loaded', {
    map,
    hasUserSpecifiedEntrance
  }));
  const isPinMovedTooFar = computed<boolean>(() => {
    return pinDistanceMeters.value > MAX_ENTRANCE_DISTANCE_METERS;
  });
  watch(isPinMovedTooFar, () => {
    if (isPinMovedTooFar.value) {
      emit('pin-moved-too-far');
    }
  });
  const isConfirmDisabled = computed<boolean>(() => {
    return isLoading.value || isPinMovedTooFar.value || props.needsUserInteraction && !hasUserSpecifiedEntrance?.value;
  });

  function updateLocation(): void {
    const center = map.value?.getCenter();
    if (!center || !geocoder.value) return;
    emit('success', {
      latitude: center.lat(),
      longitude: center.lng()
    });
  }

  function onDragEnd() {
    emit('map-dragend', pinDistanceMeters.value);
  }

  return {
    EBannerMessageVariant,
    isLoading,
    mapRef,
    isPinMovedTooFar,
    isConfirmDisabled,
    updateLocation
  };
};

__sfc_main.components = Object.assign({
  PulseLoader
}, __sfc_main.components);
export default __sfc_main;
