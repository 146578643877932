import { computed, Ref } from '@nuxtjs/composition-api'
import { IAddress } from '~/types/app/Location'

interface IInput {
  userInput?: Ref<string>
  address: Ref<IAddress | undefined>
}

export default function useFullAddressString({
  userInput,
  address,
}: IInput): Ref<string> {
  return computed(() => {
    let fullAddress =
      !!userInput && userInput.value !== ''
        ? userInput.value
        : address.value?.street_address_1

    if (!fullAddress) return ''

    if (address.value?.postal_code && address.value?.city) {
      fullAddress += `, ${address.value.postal_code} ${address.value.city}`
    }

    if (address.value?.country) {
      fullAddress += `, ${address.value.country}`
    }

    return fullAddress
  })
}
