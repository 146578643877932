import { render, staticRenderFns } from "./AddressEditModal.vue?vue&type=template&id=2b2f9968&scoped=true&"
import script from "./AddressEditModal.vue?vue&type=script&lang=ts&"
export * from "./AddressEditModal.vue?vue&type=script&lang=ts&"
import style0 from "./AddressEditModal.vue?vue&type=style&index=0&id=2b2f9968&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b2f9968",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OnboardingModalBackButton: require('/app/build/components/onboarding/OnboardingModalBackButton/OnboardingModalBackButton.vue').default,VIcon: require('/app/build/components/VIcon/VIcon.vue').default,Button: require('/app/build/components/Button/Button.vue').default,LocationStaticMap: require('/app/build/components/onboarding/LocationStaticMap/LocationStaticMap.vue').default,VTextField: require('/app/build/components/VTextField/VTextField.vue').default,AddressEntranceEditMap: require('/app/build/components/onboarding/AddressBook/AddressEntranceEditMap.vue').default,VModal: require('/app/build/components/VModal/VModal.vue').default})
